<template>
  <div>
    <iframe
      title="illionIframe"
      :src="iframeUrl"
      id="illion-iframe"
      scrolling="yes"
      width="100%"
      height="auto"
      class="illionIframe">
    </iframe>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { illionGetters } from '@/store/modules/illion/routines'
import illionIframeEventHandler from '@/mixins/illionIframeEventHandler'

/* eslint-disable camelcase */
export default {
  name: 'IllionIFrame',
  props: {
    origin: {
      type: String,
      required: true
    }
  },
  mixins: [illionIframeEventHandler],
  data() {
    return {
      bankStatementsUrls: [
        process.env.VUE_APP_API_ILLION_BANKSTATEMENTS_TEST_URL,
        process.env.VUE_APP_API_ILLION_BANKSTATEMENTS_URL
      ],
      callback: null
    }
  },
  computed: {
    ...mapGetters({
      iframeUrl: illionGetters.IFRAME_URL
    })
  },
  mounted() {
    // attach Crossdomain function to the global window
    window.XD = this.crossDomain()
    /**
     *Initialise listening for a message from a child iFrame
    */

    window.XD.receiveMessage(event => {
      if (event.data.includes('[iFrameSizer]')) {
        // filter out the messages from the iFrame resizer that are used to dynamically resize the iframe height.
        return
      }
      // event.data is a Json string... Need to parse and get 'event' from result
      const parsedEvent = JSON.parse(event?.data)
      parsedEvent.origin = this.origin
      this.trackIframeEvent(parsedEvent)
      if (parsedEvent.status === 'success') {
        this.$emit(parsedEvent.event, parsedEvent)
      }
    }, this.bankStatementsUrls)
  },
  beforeDestroy() {
    window.XD = undefined
    window.removeEventListener('message', this.callback, !1)
  },
  methods: {
    crossDomain() {
      let interval_id
      let last_hash
      let attached_callback
      const componentInstance = this

      return {
        /**
             * Call this function to start your window listening for messages.
             *
             * @param callback A function to execute after the message has been handled. The message event will be passed to the callback.
             * @param source_origin A string domain for the authorised domain from which we can receive messages. Messages from other domains are rejected.
             */
        receiveMessage: function (callback, source_origin) {
          // browser supports window.postMessage
          if (window.postMessage) {
            // bind the callback to the actual event associated with window.postMessage
            if (callback) {
              attached_callback = function (e) {
                if (
                  (typeof source_origin === 'string' && e.origin !== source_origin) ||
                  (Object.prototype.toString.call(source_origin) === '[object Function]' && source_origin(e.origin) === !1) ||
                  (Array.isArray(source_origin) && !source_origin.includes(e.origin))
                ) {
                  return !1
                }
                callback(e)
              }
            }
            if (window.addEventListener) {
              componentInstance.callback = attached_callback
              window[callback ? 'addEventListener' : 'removeEventListener']('message', attached_callback, !1)
            } else {
              window[callback ? 'attachEvent' : 'detachEvent']('onmessage', attached_callback)
            }
          } else {
            // a polling loop is started & callback is called whenever the location.hash changes
            interval_id && clearInterval(interval_id)
            interval_id = null
            if (callback) {
              interval_id = setInterval(function () {
                const hash = document.location.hash
                const re = /^#?\d+&/
                if (hash !== last_hash && re.test(hash)) {
                  last_hash = hash
                  // eslint-disable-next-line standard/no-callback-literal
                  callback({ data: hash.replace(re, '') })
                }
              }, 100)
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/
.illionIframe {
  border: 0;
  outline: 0;
  height: 500px;
}

</style>
