<template>
  <div v-if="contactInformation">
    <span>
      <i class="material-icons">contact_support</i>
        Need help?
      <button @click="handleContactInformation()" class="link-phone link">{{ contactInformation.trim() }}</button>
    </span>
  </div>
  <div v-else>
    <i class="material-icons">contact_support</i>
    <span v-if="BeforeLink">{{ BeforeLink }} </span>
    <a :href="LinkValue" class="link-phone" v-if="LinkLabel && $config.whitelabel.components.needHelpLinkEnabled">
      {{ LinkLabel }}
    </a>
    <span v-if="AfterLink"> {{ AfterLink }}</span>
  </div>
</template>

<script>
export default {
  name: 'NeedHelp',
  data() {
    return {
      BeforeLink: this.$dictionary?.needHelpText.beforeLink || '',
      AfterLink: this.$dictionary?.needHelpText.afterLink || '',
      LinkLabel: this.$dictionary?.needHelpText.linkLabel || '',
      LinkValue: this.$dictionary?.needHelpText.linkValue || ''
    }
  },
  props: {
    contactInformation: {
      type: String
    }
  },
  methods: {
    handleContactInformation() {
      const isEmail = this.contactInformation.includes('@', 0)

      if (isEmail) {
        window.location.href = `mailto:${isEmail}`
      } else {
        // phone
        window.open(`tel:${this.contactInformation}`, '_self')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables';

/deep/ {
  .link-phone {
    color: $color-link;
    text-decoration: none;

    &:hover {
      color: $color-link-hover;
    }
  }
  .material-icons{
    color: $color-primary;
    font-size: 1.1rem;
  }
}
</style>
