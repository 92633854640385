<template>
  <div class="accordion-container">
    <i class="icon material-icons notification-icon">notifications_none</i>
    <div class="description-container">
      <p v-if="isAccordion" class="description-paragraph-accordion" :class="{ 'without-ellipsis': item.clicked }">
        {{ item.description }}
      </p>
      <p class="description-paragraph" v-else>
        {{ item.description }}
      </p>
      <span>{{ item.creationDate | dateFormat('P') }}</span>
    </div>
    <button v-if="isAccordion" @click.prevent="onShowNotification(item)" type="button" class="notification-btn">
      <i v-if="!item.clicked" class="icon material-icons">add</i>
      <i v-if="item.clicked" class="icon material-icons">remove</i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'AccordionItem',
  props: {
    item: {
      required: true
    },
    isAccordion: {
      required: false
    },
    hasNotificationUpdateButton: {
      required: false
    }
  },
  methods: {
    onShowNotification(item) {
      this.$emit('show-notification', item)
    },
    onUpdateNotification(item) {
      this.$emit('update-notification', item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/swoop/variables';
.accordion-container {
  background-color: $color-white;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-primary-100);
  border-radius: 8px;
  min-height: 68px;
  margin-bottom: 5px;
  padding: 10px 0;
}
.notification-btn {
  margin-left: auto;
  margin-right: 15px;
  width: 24px;
  height: 24px;
  background-color: var(--color-primary-100);
  border-radius: 100px;
  border: none;
  color: var(--color-primary-300);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .icon {
    font-size: 16px;
  }
}
.notification-icon {
  min-width: 24px;
  height: 24px;
  margin-left: 15px;
  background-color: var(--color-primary-100);
  border-radius: 100px;
  color: var(--color-primary-300);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background-color: var(--color-secondary-100);
  }
}
.notification-update-btn {
  margin-left: 15px;
  width: 24px;
  height: 24px;
  background-color: var(--color-primary-100);
  border-radius: 100px;
  border: none;
  color: var(--color-primary-300);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .icon {
    font-size: 16px;

    &.active {
      background-color: var(--color-secondary-100);
    }
  }
}

.description-container {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  line-height: 18px;

  .description-paragraph-accordion {
    margin-bottom: 0;
    color: var(--color-primary-500);
    font-size: 14px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.without-ellipsis {
      text-overflow: initial;
      overflow: initial;
      white-space: initial;
      width: auto;
    }
  }

  .description-paragraph {
    margin-bottom: 0;
    color: var(--color-primary-500);
    font-size: 14px;
  }

  span {
    color: var(--color-primary-400);
    font-size: 12px;
  }
}
</style>
