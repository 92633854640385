<template>
  <div class="card">
    <div class="header">
      <span class="ui title text dark" v-if="!isShowNotifications">Tasks to complete</span>
      <span class="ui title text dark" v-if="isShowNotifications">Latest notifications</span>
      <button @click.prevent="showNotifications" class="circular ui icon button small" v-if="!isShowNotifications">
        <i class="icon material-icons">notifications_none</i>
      </button>
      <button @click.prevent="showNotifications" class="circular ui icon button small" v-if="isShowNotifications">
        <i class="icon material-icons">close</i>
      </button>
    </div>
    <div class="notifications-list" v-if="notificationsList.length && isShowNotifications">
      <NotificationsList :notificationsList="notificationsList" />
    </div>
    <div class="fullwidth accordion-container m-t-15" v-if="!notificationsList.length && isShowNotifications">No notifications. All caught up</div>
    <nav class="card-list" v-if="!isShowNotifications">
      <router-link class="card-list-item" :to="{ name: 'integrations' }" v-if="$config.whitelabel.features.linkBankAccount">
        <span
          :class="isLinkBankAccountComplete ? 'active' : ''"
          :is-loading="bankMandateStatusMax && bankMandateStatusMax !== 'ProcessingCompleted' && bankMandateStatusMax !== 'FetchingDataError'"
          class="done-icon"
        >
          <i v-if="isLinkBankAccountComplete" class="icon material-icons">done</i>
          <i v-else class="icon material-icons error-icon">error_outline</i>
        </span>
        Link Bank Account
      </router-link>
      <router-link class="card-list-item" :to="{ name: 'data-room', params: { step: 'pitchDeck' } }" v-if="$config.whitelabel.components.pitchDeckEnabled">
        <span :class="isPitchDeckUploaded === true ? 'active' : ''" :is-loading="isPitchDeckUploaded === null" class="done-icon">
          <i v-if="isPitchDeckUploaded === true" class="icon material-icons">done</i>
          <i v-else class="icon material-icons error-icon">error_outline</i>
        </span>
        Upload your Pitch Deck
      </router-link>
      <router-link class="card-list-item" :to="{ name: 'savings-services' }" v-if="$config.whitelabel.features.savings">
        <span :class="companyData && companyData.isAppliedForCostSavings === true ? 'active' : ''" class="done-icon">
          <i v-if="companyData && companyData.isAppliedForCostSavings === true" class="icon material-icons">done</i>
          <i v-else class="icon material-icons error-icon">error_outline</i>
        </span>
        Search For Savings
      </router-link>
    </nav>
  </div>
</template>

<script>
import NotificationsList from './NotificationsList'

export default {
  name: 'TasksToComplete',
  components: {
    NotificationsList
  },
  props: {
    notificationsList: {
      required: true
    },
    companyData: {
      required: true
    },
    companyState: {
      required: true
    },
    bankMandateList: {
      required: true
    },
    bankMandateStatusMax: {
      required: true
    },
    tenantName: {
      type: String
    },
    documentsList: {
      type: Array
    }
  },
  data() {
    return { isShowNotifications: false, active: false }
  },
  computed: {
    isStartNewFundingSearchComplete() {
      return (
        this.companyState &&
        this.companyState.companyState === 'Completed' &&
        this.companyState.fundingApplicationState === 'Completed' &&
        this.companyState.financialState === 'Completed'
      )
    },
    isLinkBankAccountComplete() {
      return this.bankMandateList && this.bankMandateList.length && this.bankMandateStatusMax !== 'FetchingDataError'
    },
    isPitchDeckUploaded() {
      if (!this.documentsList) return null
      return !!this.documentsList.find(({ type, title }) => type === 'FundingDocuments' && title === 'Pitch Deck')
    }
  },
  methods: {
    showNotifications() {
      this.isShowNotifications = !this.isShowNotifications
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';
.card {
  margin: 10px;
  max-width: 250px;
  max-height: 300px;
}

a {
  text-decoration: none;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  font-size: 20px;
}
.card-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 1rem;
}
.card-list-item {
  padding: 17px 0;
  border-top: 1px solid var(--color-primary-100);
  width: 100%;
  color: $color-main;
}
.done-icon {
  $size: 24px;
  display: inline-block;
  background-color: transparent;
  width: $size;
  height: $size;
  border-radius: 50%;
  margin: 0;
  transform: translateY(4px);
  text-align: center;
  .icon {
    color: var(--color-warning-600);
    font-size: 16px;
    line-height: $size;
    margin-right: 0px;
    &.error-icon {
      font-size: 1.5rem;
      transform: translateY(2px);
    }
  }
  &.active {
    background-color: var(--color-secondary-100);
    .icon {
      color: var(--color-primary-300);
    }
  }
}
.ui.icon.circular {
  padding: 0;
  height: 32px;
  width: 32px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-container {
  background-color: $color-white;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-primary-100);
  border-radius: 8px;
  min-height: 68px;
  margin-bottom: 5px;
  padding: 1rem;
}
@media only screen and (max-width: 770px) {
  .ui.card {
    margin: auto;
    width: 250px;
    max-width: 250px;
  }
  .card-item {
    padding: 1rem;
    max-width: 250px;
  }
}
</style>
