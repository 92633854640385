<template>
  <div :class="{ 'full-screen': isFullScreen, 'half-screen': !isFullScreen }">
    <div class="banner-container">
      <div class="banner" :class="{ 'cashflow-forecasting': content === 'cashflowforecasting', 'credit-passport': content === 'creditpassport' }">
        <img class="banner-image" :src="bannerData.imagePath" :alt="bannerData.altText" />
        <div class="content">
          <p class="title">{{ bannerData.title }}</p>
          <p class="text">
            {{ bannerData.text }}
          </p>
          <button class="btn btn-transparent" @click="clickedBanner()">{{ bannerData.buttonText }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { amplitudeEvents } from '@/utils/constants'

export default {
  name: 'DashboardBanner',
  props: {
    content: {
      required: false,
      default: 'creditpassport',
      type: String
    },
    isFullScreen: {
      required: false,
      type: Boolean
    }
  },
  computed: {
    bannerData() {
      if (this.content === 'creditpassport') {
        return {
          imagePath: require('@/assets/images/credit-passport/credit-passport.svg'),
          altText: 'credit passport banner image',
          title: 'Credit Passport',
          text: "Credit Passport® is the secure way to see your business credit score. It doesn't impact their credit profile and you're in total control of the data you share.",
          buttonText: 'Link your bank account data',
          trackingEvent: { eventType: amplitudeEvents.CreditScore.CHC_PRODUCT_BANNER_CLICK },
          redirect: { name: 'credit-health-check' }
        }
      }
      if (this.content === 'cashflowforecasting') {
        return {
          imagePath: require('../../cashflowForecast/assets/images/cashflow-and-forecasting-group.svg'),
          altText: 'cashflow forecasting banner image',
          title: 'Cashflow & Forecasting',
          text: 'Link your accountancy software and access up-to-date financial data, better understand your cashflow, and make stronger decisions for the future.',
          buttonText: 'Link your accountancy software',
          trackingEvent: { eventType: amplitudeEvents.CashflowAndForecasting.CLICK_BANNER },
          redirect: { name: 'cashflow-forecasting-integration' }
        }
      }
      return null
    }
  },
  methods: {
    clickedBanner() {
      this.$ma.trackEvent({ ...this.bannerData.trackingEvent, page: this.$route.name })
      this.$router.push(this.bannerData.redirect)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables';

.btn-transparent {
  width: fit-content;
  background-color: $color-white;
  color: $color-black;
  padding: 5px 15px;
  margin-bottom: 20px;
  border: none;
  .extra-button-text {
    font-weight: bold;
  }
}

.content {
  margin: auto;
  width: 100%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .title {
    margin-top: 5px;
    font-size: 22px;
    font-weight: bold;
    line-height: 26px;
    margin-bottom: 5px;
  }
  .text {
    font-size: 14px;
    line-height: 22px;
  }
}

.banner {
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;

  &.credit-passport {
    background-image: url(../../../assets/images/credit-passport-banner.png);
  }
  &.cashflow-forecasting {
    background-image: url(../../cashflowForecast/assets/images/cashflow-and-forecast-banner-background.svg);
  }
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 15px;
  display: flex;
  align-items: center;
}

.banner-container {
  width: 100%;
  height: 100%;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-screen {
  margin: auto 8px;
  height: 220px;
  .banner-container {
    .banner .banner-image {
      width: 40%;
      height: 60%;
    }
  }
}

.half-screen {
  margin: 4px;
  .banner-container {
    min-height: 300px;
    .banner .banner-image {
      margin: 4px;
      width: 40%;
      height: 40%;
    }
  }
}

@media only screen and (max-width: 1170px) {
  .half-screen .banner-container .banner .banner-image {
    width: 40%;
    height: 25%;
  }
  .banner-image {
    margin: 8px auto;
  }
  .btn-transparent {
    font-size: 14px;
  }
}

@media only screen and (max-width: 740px) {
  .half-screen {
    margin: 8px auto;
  }
}

@media only screen and (max-width: 400px) {
  .half-screen .banner-container .banner .banner-image {
    margin: 8px auto;
    width: 30%;
    height: 30%;
  }
}

@media only screen and (max-width: 400px) {
  .full-screen {
    margin: auto;
    margin: 30px auto auto auto;
  }
  .banner-container .banner {
    height: 100%;
    border-radius: 15px;
    flex-direction: column;
    .btn-transparent {
      margin: 0 auto 20px auto;
    }
    .content {
      padding: 10px;
      .title {
        text-align: center;
      }
      .text {
        padding: 0 12px;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .full-screen .banner-container .banner .banner-image {
    width: 35%;
    height: 35%;
  }
}
</style>
