<template>
  <div ref="svgContainer"></div>
</template>

<script lang="js">
export default {
  name: 'svg-handler',
  props: {
    svgString: {
      type: String,
      required: true
    }
  },
  mounted() {
    const parser = new DOMParser()
    const svgDoc = parser.parseFromString(this.svgString, 'image/svg+xml')
    const svgElement = svgDoc?.querySelector('svg')
    if (svgElement) {
      this.$refs.svgContainer.appendChild(svgElement)
    }
  }
}
</script>
