<template
  v-if="notificationsList && notificationsList.length">
  <div class="notification-container">
    <AccordionList
      v-if="notificationsList && notificationsList.length"
      :itemsList="notificationsList"
      :hasNotificationUpdateButton="true"
    />
  </div>
</template>

<script>
import AccordionList from '@/components/AccordionList'
export default {
  name: 'NotificationsList',
  components: { AccordionList },
  props: {
    notificationsList: {
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';
.notification-container {
  margin-top: 16px;
}
.notification {
  border-top: 1px solid var(--color-primary-100);
  width: 100%;
  padding-top: 13px;
  color: var(--color-primary-400);
}
.notification-date {
  font-size: 12px;
}
</style>
