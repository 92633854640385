<template>
  <div class="dashboard-wrapper">
    <div class="content">
      <LegacyFormReminderModal v-if="redirectFormUrl" :callback="legacyFormRedirectHandler" />

      <div class="welcome-elements">
        <WelcomeDescription :user="user" />
        <NeedHelp class="need-help" />
      </div>

      <div class="banner-container">
        <DashboardBanner class="banner" v-if="isCreditPassportEnabled" :isFullScreen="!isCashflowForcastingEnabled" content="creditpassport" />
        <DashboardBanner class="banner" v-if="isCashflowForcastingEnabled" :isFullScreen="!isCreditPassportEnabled" content="cashflowforecasting" />
      </div>

      <div class="card-container">
        <DashboardCard
          v-if="this.$config.whitelabel.components.showCoreDocumentsCard && showCoreDocumentsCard"
          class="card"
          description="Faster decisions to your funding process"
          :routeName="'Accelerate funding process'"
          imgFile="application-under-review.svg"
          imgWidth="190"
          imgHeight="160"
          alt="accelerate funding"
          @click="$modal.show('coreDocumentsModal')"
          :trackClickEvent="() => trackClickEvent('Accelerate funding process')"
        />
        <DashboardCard
          v-if="$config.whitelabel.journey.loansEnabled || $config.whitelabel.journey.grantsEnabled"
          class="card"
          description="Explore your funding matches and apply"
          :routeName="'Explore funding options'"
          :route="this.$config.whitelabel.journey.loansEnabled ? { name: 'loan-matches' } : { name: 'grant-matches' }"
          imgFile="funding.svg"
          imgWidth="190"
          imgHeight="160"
          alt="funding"
          :trackClickEvent="() => trackClickEvent('Explore funding options')"
        />
        <DashboardCard
          class="card"
          v-if="$config.whitelabel.features.savings"
          :description="'Save money with smarter decisions'"
          :routeName="'View potential savings'"
          :route="{ name: 'savings-services' }"
          imgFile="savings.svg"
          imgWidth="190"
          imgHeight="160"
          alt="savings"
          :trackClickEvent="() => trackClickEvent('View potential savings')"
        />
        <CardTasksToComplete class="card" v-bind="{ notificationsList, companyData, companyState, bankMandateList, bankMandateStatusMax, tenantName, documentsList }" />
      </div>

      <h1 class="choose-funding-title">Choose your funding</h1>
      <p>In order for us to find you the right funding, we've just got a few quick questions to get things started.</p>
      <GoalsList />
    </div>
  </div>
</template>

<script>
import GoalsList from '../../../app/product/features/goals/components/GoalsList.vue'
import { legacyLoansFormMigrations, legacyLoanFormId, amplitudeEvents } from '@/utils/constants'
import { UPDATE_LEGACY_LOANS_DATA } from '@/store/modules/legacyLoansData/routines'
import { bankMandateListRoutine, bankGetters } from '@/store/modules/bank/routines'
import DashboardBanner from '../dashboard/components/DashboardBanner'
import LegacyFormReminderModal from './components/LegacyFormReminderModal.vue'
import { getLegacyLoansFundingApplication } from '@/api/fundingApplication'
import { getLoanFormData } from '../../../app/product/api/products/index'
import { authGetters, userRoutine } from '@/store/modules/auth/routines'
import { forwardAIGetters } from '@/store/modules/forwardAI/routines'
import CardTasksToComplete from './components/CardTasksToComplete'
import accountingIntegration from '@/mixins/accountingIntegration'
import { companyGetters } from '@/store/modules/company/routines'
import WelcomeDescription from './components/WelcomeDescription'
import { tenantGetters } from '@/store/modules/tenant/routines'
import { getFinancialDataSummary } from '@/api/financialData'
import { getNotifications } from '@/api/companyNotification'
import amplitudeTracking from '@/mixins/amplitudeTracking'
import { requiredDocumentsAdapter } from '@/utils/utils'
import DashboardCard from '@/components/DashboardCard'
import { getDocumentsList } from '@/api/document'
import { mapGetters, mapActions } from 'vuex'
import NeedHelp from '@/components/NeedHelp'
import { getCompany } from '@/api/company'
import { getAllForms } from '@/api/goals'
import config from '@/config/global'

export default {
  name: 'Dashboard',
  components: {
    LegacyFormReminderModal,
    CardTasksToComplete,
    WelcomeDescription,
    DashboardBanner,
    DashboardCard,
    GoalsList,
    NeedHelp
  },
  data() {
    return {
      isBusinessDashboardSelected: false,
      opportunityApplicationList: null,
      isIntegrationAlertVisible: false,
      financialSummary: null,
      banksPerformance: null,
      businessSpending: null,
      redirectFormUrl: null,
      notificationsList: [],
      isAlertLoaded: false,
      documentsList: null,
      currentSpend: null,
      companyName: null,
      companyData: null,
      isLoading: true
    }
  },
  mixins: [accountingIntegration, amplitudeTracking],
  computed: {
    ...mapGetters({
      forwardAIIntegration: forwardAIGetters.FORWARDAI_INTEGRATION,
      bankMandateStatusMax: bankGetters.BANK_MANDATE_STATUS_MAX,
      currentCompanyId: companyGetters.COMPANY_CURRENT_ID,
      bankMandateStatus: bankGetters.BANK_MANDATE_STATUS,
      isProductsUnlocked: authGetters.PRODUCTS_UNLOCKED,
      bankMandateList: bankGetters.BANK_MANDATE_LIST,
      companyState: companyGetters.COMPANY_STATE,
      isInvitedUser: authGetters.IS_INVITED_USER,
      bankMandateId: bankGetters.BANK_MANDATE_ID,
      tenantName: tenantGetters.TENANT_NAME,
      companyId: companyGetters.COMPANY_ID,
      user: authGetters.USER
    }),
    isCreditPassportEnabled() {
      return !this.isInvitedUser && config.whitelabel.components.creditPassportEnabled
    },
    isCashflowForcastingEnabled() {
      return !this.isInvitedUser && config.whitelabel.features.cashflowForecastingEnabled
    },
    showCoreDocumentsCard() {
      const coreDocs = requiredDocumentsAdapter({
        documentsList: this.documentsList || [],
        requiredDocuments: {
          hasBankStatements: 6,
          filedAccounts: 1,
          hasManagementAccounts: true,
          hasVATReturns: true,
          proofOfIdentity: true,
          proofOfAddress: true
        },
        isPrivateLimitedCompany: false,
        opportunity: {
          bankStatements: 6
        }
      })
      return !(this.documentsList && coreDocs.every(doc => doc.isDocumentUploaded))
    }
  },
  methods: {
    ...mapActions({
      requestUser: userRoutine.TRIGGER,
      getBankMandateList: bankMandateListRoutine.TRIGGER,
      updateLegacyLoansData: UPDATE_LEGACY_LOANS_DATA
    }),
    async legacyFormRedirectHandler() {
      const res = await getLoanFormData(this.companyId)
      if (res.data) {
        this.updateLegacyLoansData('legacyLoansFormData', JSON.stringify(res.data))
        this.$router.push(this.redirectFormUrl)
      }
    },
    setAlertIsLoaded() {
      this.isAlertLoaded = true
    },
    async initCompanyRelatedData() {
      try {
        await Promise.all([
          this.fillCompanyData(),
          this.fillDocumentsList(),
          this.fillBankMandateList(),
          this.fillNotificationsList(),
          this.requestForwardAIIntegration() // mixin
        ])
        await this.fillCompanyState()
        this.setAlertIsLoaded()
      } catch (e) {
        console.log(e)
      }
    },
    async fillCompanyState() {
      const forwardAIIntegrationSuccessState = ['connected', 'ready']
      if (this.$config.whitelabel.features.linkBankAccount) {
        this.isIntegrationAlertVisible =
          !(this.bankMandateList && this.bankMandateList.length) || !forwardAIIntegrationSuccessState.includes(this.forwardAIIntegration && this.forwardAIIntegration.status)
      } else {
        this.isIntegrationAlertVisible =
          !forwardAIIntegrationSuccessState.includes(this.forwardAIIntegration && this.forwardAIIntegration.status) && this.$config.whitelabel.features.linkAccountancy
      }
    },
    fillFinancialSummary() {
      this.financialSummary = null
      return getFinancialDataSummary(this.companyId)
        .then(res => {
          this.financialSummary = res.data
        })
        .catch(() => {
          this.financialSummary = {}
        })
    },
    fillCompanyData() {
      this.companyData = null
      return this.updateCompanyData()
    },
    updateCompanyData() {
      return getCompany(this.companyId)
        .then(res => {
          this.companyData = res.data
          this.isSoleTrader = this.companyData?.companyStructures?.includes('Sole Trader')
        })
        .catch(() => {
          this.companyData = {}
        })
    },
    fillNotificationsList() {
      return getNotifications(this.companyId)
        .then(res => {
          this.notificationsList = res.data.slice(0, 2)
        })
        .catch(() => {
          this.notificationsList = []
        })
    },
    fillDocumentsList() {
      this.documentsList = null
      return getDocumentsList(this.companyId)
        .then(res => {
          this.documentsList = res.data
        })
        .catch(() => {
          this.documentsList = {}
        })
    },
    fillBankMandateList() {
      return this.getBankMandateList(this.companyId)
    },
    async closeAlert() {
      this.isIntegrationAlertVisible = false
    },
    trackPageView() {
      this.$ma.trackEvent({
        eventType: 'PV Dashboard',
        eventProperties: {
          advisor: false
        }
      })
    },
    trackClickEvent(action) {
      this.$ma.trackEvent({
        eventType: 'Dashboard actions',
        eventProperties: {
          action
        }
      })
    },
    trackPasswordCreated() {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.passwordCreated
      })
    }
  },
  async created() {
    this.isLoading = true
    await this.initCompanyRelatedData()
    this.requestUser().finally(() => {
      this.isLoading = false
    })
  },
  async beforeMount() {
    // if the user filled out the legacy 'loans' form a modal prompts them to fill out the new form.
    const loansFormCompleted =
      this.user?.goalsCompleted &&
      this.user.goalsCompleted.filter(goal => {
        return goal.type === 'Loan'
      }).length > 0

    if (!this.$config.whitelabel.journey.loansEnabled || !loansFormCompleted) {
      this.redirectFormUrl = null
      return
    }
    const completedFormIds = this.user?.goalsCompleted?.map(goal => goal.formId.toLowerCase())
    if (completedFormIds?.includes(legacyLoanFormId)) {
      const { data } = await getAllForms()
      const loanForms = data?.filter(form => form.type === 'Loan')
      const loanFormIds = loanForms?.map(form => form.formId.toLowerCase())
      const isDynamicFormAlreadyFilled = completedFormIds.some(completedFormId => loanFormIds?.includes(completedFormId))
      if (isDynamicFormAlreadyFilled) {
        this.redirectFormUrl = null
      } else {
        const res = await getLegacyLoansFundingApplication(this.companyId)
        const fundingPurpose = res.data?.fundingPurposes[0]?.toLowerCase()
        const redirectUrl = legacyLoansFormMigrations[fundingPurpose]
        if (redirectUrl) {
          this.redirectFormUrl = `goal/${redirectUrl}`
        } else {
          this.redirectFormUrl = 'goals'
        }
      }
    }
  },
  mounted() {
    window.localStorage.removeItem('enquiryFormFinished')
    window.localStorage.removeItem('accountCreated')
    if (this.$route.params?.passwordCreated) {
      this.trackPasswordCreated()
    }
    this.trackPageView()
  },
  watch: {
    companyState(newState) {
      if (!this.isProductsUnlocked) {
        this.$router.push(this.$config.whitelabel.journey.defaultOnBoardingPage)
      }
    },
    companyId() {
      this.initCompanyRelatedData()
    },
    currentCompanyId(companyId) {
      if (this.$apm) {
        this.$apm.setCustomContext({
          companyId: companyId
        })
      }
      this.initCompanyRelatedData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
.dashboard-wrapper {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;

  .content {
    margin: 4vh 0;
    width: calc(92vw - #{$sidebar-width});
    max-width: 1200px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .welcome-elements {
      position: relative;
      justify-self: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-height: 70px;
      .need-help {
        margin-top: 10px;
      }
    }
    .choose-funding-title {
      margin-top: 30px;
      padding-left: 5px;
      border-left: 4px solid var(--color-primary-500);
    }
    .banner-container {
      margin-bottom: 30px;
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .card-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
}

@media only screen and (max-width: 1420px) {
  .dashboard-wrapper .content .card-container {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: $breakpoint-md) {
  .dashboard-wrapper {
    padding: 16px;
    .content {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 770px) {
  .dashboard-wrapper .content {
    .banner-container {
      margin-top: 40px;
    }
    .welcome-elements {
      flex-direction: column;
      width: 100%;
      .need-help {
        justify-self: left;
      }
    }
  }
}
@media only screen and (max-width: 740px) {
  .dashboard-wrapper .content .banner-container {
    flex-direction: column;
  }
}
@media only screen and (max-width: 570px) {
  .dashboard-wrapper .content {
    .card-container {
      height: 100%;
      margin-bottom: 30px;
    }
  }
}
</style>
