<template>
  <!-- // TODO: HF to ensure that modal close icon is not setting the hiddenflag -->
  <modal :clickToClose="false" name="legacyFormReminderModal" classes="v--modal" height="auto" :width="500">
    <section v-if="modalStart" class="legacy-form-reminder-modal text-center" data-testid="modal-copy">
      <img src="@/assets/images/icons/icons_energy.svg" alt="Supercharge" width="auto" height="40" />
      <h2 class="modal-title">Good news!</h2>
      <div class="modal-text text-center">
        <p>
          We have recently improved our loan matches, we would just like a little extra information about your business and you will be matched with the best results you’ve been
          looking for!
        </p>
      </div>
      <div class="modal-actions">
        <button class="btn btn-primary" type="button" @click="goToForm()">
          Improve your matches
        </button>
      </div>
    </section>
    <IllionIFrame v-else mode="modal" data-testid="modal-iframe" origin="popup" @submit_all="setModalFlag" />
  </modal>
</template>

<script>
import IllionIFrame from '@/features/illion/IllionIFrame'
import { amplitudeEvents } from '@/utils/constants'

export default {
  props: {
    callback: {
      type: Function,
      required: true
    }
  },
  name: 'LegacyFormReminderModal',
  components: {
    IllionIFrame
  },
  mounted() {
    this.$modal.show('legacyFormReminderModal')
  },
  data() {
    return {
      amplitudeEvents,
      modalStart: true
    }
  },
  methods: {
    goToForm() {
      this.callback()
      this.$modal.hide('legacyFormReminderModal')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/swoop/_variables';

.close-modal {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 20px;
  line-height: 0;
  z-index: 10;
}

.legacy-form-reminder-modal {
  padding: 1rem;
  position: relative;

  .background-triangles {
    position: absolute;
  }
  .modal-title {
    font-size: 26px;
    font-weight: bold;
  }
  .modal-text {
    margin: 2rem 0;
  }
  .modal-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .dismiss-actions {
    display: flex;
    margin-top: 1rem;
  }
  .btn {
    display: flex;
    align-items: center;
    img {
      margin-right: 1rem;
    }
  }
  .btn-icon {
    filter: invert(1);
  }
}
</style>
