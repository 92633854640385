<template>
  <div class="wrapper">
    <div class="spinner-container" v-if="loading"><spinner :size="42" :loader="true" color /></div>
    <div v-if="layoutMode === 'grid'" class="is-grid">
      <div class="tile-container">
        <FormOverlay v-if="isPageOverlayOpen">
          <EnquiryForm  @refetch-products="refetchProducts" />
        </FormOverlay>
        <div
          class="tile"
          v-for="(goal, index) in goalsList"
          :data-cy="goal.title"
          :data-testid="`goal-button-${index + 1}`"
          :key="goal.title"
          :tabindex="index + 1"
          @keyup.enter="handleSelect(goal)"
        >
          <GoalTileContent :goal="goal" :openOverlayForm="openOverlayForm" layoutMode="grid" />
        </div>
      </div>
    </div>
    <div v-if="layoutMode === 'slider'" class="is-slider">
      <div class="tile-container">
        <div class="tab">
          <em
            class="material-icons arrow prev"
            :class="{ disabled: leftArrowDisabled, 'arrow-active': leftArrowActive, 'arrow-active-remove': !leftArrowActive }"
            @click="scroll('left')"
            >keyboard_arrow_left_icon</em
          >
          <div class="scrollable" ref="scrollable" v-on:scroll="handleScroll">
            <FormOverlay v-if="isPageOverlayOpen">
             <EnquiryForm  @refetch-products="refetchProducts" />
           </FormOverlay>
            <div
              class="tile"
              v-for="(goal, index) in goalsList"
              :data-cy="goal.title"
              :data-testid="`goal-button-${index + 1}`"
              :key="goal.title"
              :tabindex="index + 1"
              @keyup.enter="handleSelect(goal)"
            >
              <GoalTileContent :goal="goal" :openOverlayForm="openOverlayForm" layoutMode="slider" />
            </div>
          </div>
          <em
            class="material-icons arrow next"
            :class="{ disabled: rightArrowDisabled, 'arrow-active': rightArrowActive, 'arrow-active-remove': !rightArrowActive }"
            @click="scroll('right')"
            >keyboard_arrow_right_icon</em
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import deepLinking from '@/mixins/deepLinking'
import { dynamicFormListGetters, updateDynamicFormListRoutine } from '@/store/modules/dynamicFormList/routines'
import GoalTileContent from './GoalTileContent.vue'
import { authGetters } from '@/store/modules/auth/routines'
import { currentFormNameRoutine } from '@/store/modules/currentFormDetails/routines'
import { pageOverlayRoutine, pageOverlayGetters } from '@/store/modules/pageOverlay/routines'
import EnquiryForm from '@product/features/goals/components/EnquiryForm'
import FormOverlay from '@/components/FormOverlay'
import { companyGetters } from '@/store/modules/company/routines'

export default {
  name: 'goals-list',
  components: { GoalTileContent, FormOverlay, EnquiryForm },
  mixins: [deepLinking],
  data() {
    return {
      filterGoalsByType: this.$route?.query.type || null,
      inititalMode: null, // dashboard = 'slider', all other routes = 'grid'
      goalsList: [],
      loading: true,
      layoutMode: null,
      leftArrowDisabled: true,
      leftArrowActive: false,
      rightArrowDisabled: false,
      rightArrowActive: false,
      resizeHandler: null,
      stageOnboardingForms: false
    }
  },
  computed: {
    ...mapGetters({
      dynamicFormList: dynamicFormListGetters.DYNAMIC_FORM_LIST,
      user: authGetters.USER,
      companyId: companyGetters.COMPANY_ID,
      isPageOverlayOpen: pageOverlayGetters.IS_PAGE_OVERLAY_OPEN
    })
  },
  created() {
    // display as a slider if on the dashboard and not mobile, otherwise display as grid (/goals)
    const path = this.$route.path
    this.inititalMode = path === '/dashboard' ? 'slider' : 'grid'

    if (path === '/dashboard' && window.innerWidth > 768) {
      this.layoutMode = 'slider'
    } else {
      this.layoutMode = 'grid'
    }
  },
  async mounted() {
    if (this.inititalMode === 'slider') {
      this.resizeHandler = this.handleMobileMode
      window.addEventListener('resize', this.resizeHandler)
    }

    await this.updateDynamicFormList()

    if (this.inititalMode === 'grid' && !this.dynamicFormList?.length) return this.redirectToDashboardOrLogin()
    const goalsCompleted = this.user?.goalsCompleted?.map(goal => goal.formId) || []
    const goals = []

    this.dynamicFormList.filter(form => {
      if (!this.checkIfFormIsEnabled(form)) return false
      let formIcon = ''
      try {
        formIcon = decodeURIComponent(
          window
            .atob(form.icon)
            .split('')
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
        )
      } catch (e) {
        console.error(e)
      }
      goals.push({
        formId: form.formId,
        title: form.displayName,
        description: form.description,
        redirect: form.route,
        icon: formIcon,
        type: form.type,
        goalName: form.name,
        isCompleted: goalsCompleted?.includes(form?.formId?.toLowerCase())
      })
    })
    this.goalsList = goals
    this.loading = false
  },
  methods: {
    ...mapActions({
      updateDynamicFormList: updateDynamicFormListRoutine.TRIGGER,
      setCurrentFormName: currentFormNameRoutine.TRIGGER,
      setPageOverlay: pageOverlayRoutine.TRIGGER
    }),
    handleMobileMode() {
      this.layoutMode = window.innerWidth <= 768 ? 'grid' : 'slider'
    },
    handleScroll() {
      const offset = 2
      const element = this.$refs.scrollable
      const scrollPosition = element.scrollLeft + offset
      const scrollAvailable = element.scrollWidth - element.clientWidth
      if (scrollAvailable <= scrollPosition) {
        if (!this.rightArrowDisabled) {
          this.rightArrowDisabled = true
        }
      }
      if (scrollAvailable > scrollPosition) {
        if (this.rightArrowDisabled) {
          this.rightArrowDisabled = false
        }
      }
      if (scrollPosition <= offset) {
        if (!this.leftArrowDisabled) {
          this.leftArrowDisabled = true
        }
      }
      if (scrollPosition > offset) {
        if (this.leftArrowDisabled) {
          this.leftArrowDisabled = false
        }
      }
    },
    arrowClickEffectHandler(direction) {
      if (direction === 'left' && !this.leftArrowDisabled) {
        this.leftArrowActive = true
        setTimeout(() => {
          this.leftArrowActive = false
        }, 300)
      }
      if (direction === 'right' && !this.rightArrowDisabled) {
        this.rightArrowActive = true
        setTimeout(() => {
          this.rightArrowActive = false
        }, 300)
      }
    },
    scroll(direction) {
      this.arrowClickEffectHandler(direction)
      const scrollAmount = 400
      const scroll = document.querySelectorAll('.scrollable')
      const position = this.$refs.scrollable.scrollLeft
      if (direction === 'left') {
        scroll[0].scrollTo({
          left: position - scrollAmount,
          behavior: 'smooth'
        })
      } else {
        scroll[0].scrollTo({
          left: position + scrollAmount,
          behavior: 'smooth'
        })
      }
    },
    refetchProducts(value) {
      this.setCurrentFormName(value)
      this.$emit('refetch-products', value)
    },
    openOverlayForm(newSelectedGoal) {
      if (Object.keys(newSelectedGoal).length) {
        this.handleAmplitudeClickEvent(newSelectedGoal.title)
        this.checkForDeepLinkShift()
      }
      this.setCurrentFormName(newSelectedGoal?.goalName)
      this.setPageOverlay(!this.isPageOverlayOpen)
    },
    handleSelect(newSelectedGoal) {
      if (Object.keys(newSelectedGoal).length) {
        this.handleAmplitudeClickEvent(newSelectedGoal.title)
        this.checkForDeepLinkShift()

        this.$router.push({
          name: 'get-started',
          params: { fundingReason: this.goalsList.find(goal => goal.title === newSelectedGoal.title)?.goalName }
        })
      }
    },
    handleAmplitudeClickEvent(goalTitle) {
      this.$ma.trackEvent({
        eventType: 'PV SME Goal Picker',
        eventProperties: { Goal: goalTitle }
      })
    },
    redirectToDashboardOrLogin() {
      if (this.companyId) return this.$router.push({ name: 'dashboard' })
      return this.$router.push({ name: 'login' })
    },
    checkIfFormIsEnabled(form) {
      if (this.$config.whitelabel.journey.disabledGoals.includes(form.name)) return false
      if (this.filterGoalsByType && this.filterGoalsByType !== form?.type?.toLowerCase()) return false
      switch (form?.type?.toLowerCase()) {
        case 'equity':
          if (!this.$config.whitelabel.journey.equityEnabled) return false
          break
        case 'grant':
          if (!this.$config.whitelabel.journey.grantsEnabled) return false
          break
        case 'loan':
          if (!this.$config.whitelabel.journey.loansEnabled) return false
          break
        default:
          break
      }
      return true
    }
  },
  watch: {
    goalsList(newValue) {
      if (this.inititalMode === 'grid' && !newValue.length) this.redirectToDashboardOrLogin()
      if (newValue.length === 1) {
        this.$router.replace({
          name: 'goals-form',
          params: {
            formRoute: newValue[0].redirect
          }
        })
      }
    }
  },
  beforeDestroy() {
    if (this.inititalMode === 'slider') {
      window.removeEventListener('resize', this.resizeHandler)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
@import '@/assets/styles/swoop/_mixins.scss';

.spinner-container {
  @include flex-display(column, center, center);
}

.wrapper {
  background-color: #f9fafb;
  height: 100%;
  width: 100%;
  .is-grid {
    .tile-container {
      height: 100%;
      display: grid;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      margin-bottom: 24px;

      @media only screen and (max-width: $breakpoint-md) {
        @include flex-display(column, normal, center);
        grid-row-gap: 16px;
      }
      //these are finely tuned to keep the tiles on 2 rows until mobile layout triggers
      @media only screen and (max-width: 1245px) {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
      }
      @media only screen and (max-width: 1110px) {
        grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
      }
    }
  }
  .is-slider {
    .tile-container {
      position: relative;
      width: 100%;
      .tab {
        display: flex;
        align-items: center;
        overflow: hidden;

        .arrow {
          color: var(--color-primary-400);
          z-index: 10;
          position: absolute;
          width: 30px;
          cursor: pointer;
          border: 1px solid var(--color-neutral-100);
          border-radius: 8px;
          background-color: $color-white;
          box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);
          &:hover {
            color: $color-black;
          }
        }
        .arrow.prev {
          left: -10px;
        }
        .arrow.next {
          right: -10px;
        }
        .arrow.disabled {
          color: var(--color-primary-100);
        }
        .arrow.arrow-active {
          transition: background-color 0.5s;
          background-color: var(--color-primary-100);
        }
        .arrow.arrow-active.arrow-active-remove {
          background-color: transparent;
          transition-delay: 0.5s;
        }
        .scrollable {
          margin-bottom: 30px;
          display: flex;
          overflow-y: scroll;
          /* Hide scrollbar for Chrome, Safari and Opera */
          &::-webkit-scrollbar {
            display: none;
          }
          /* Hide scrollbar for IE, Edge and Firefox */
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
      }
    }
  }
}
</style>
