<template>
  <div class="dashboard-card card">
    <div class="contents">
      <div class="image">
        <img :src="src" :alt="alt" v-if="imgFile" :width="imgWidth" :height="imgHeight" />
      </div>
      <h3 class="description">{{ description }}</h3>
      <router-link v-if="route && trackClickEvent" :to="route" class="card-button" @click.native="trackClickEvent">{{ routeName }}</router-link>
      <router-link v-else-if="route && !trackClickEvent" :to="route" class="card-button">{{ routeName }}</router-link>
      <button v-else @click="this.buttonClick" :data-testid="routeName" class="card-button">{{ routeName }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dashboard-card',
  props: {
    description: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    route: {
      type: Object
    },
    alt: {
      type: String
    },
    imgFile: {
      type: String
    },
    imgWidth: {
      type: String
    },
    imgHeight: {
      type: String
    },
    trackClickEvent: {
      type: Function
    }
  },
  computed: {
    src() {
      return `/img/${this.imgFile}`
    }
  },
  methods: {
    buttonClick($event) {
      if (this.trackClickEvent) {
        this.trackClickEvent()
      }
      this.$emit('click', $event)
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin: auto;
  padding: 10px;
}
.dashboard-card {
  margin: 10px;
  max-width: 250px;
  max-height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .contents {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .image {
      align-self: center;
      justify-self: center;
    }
    .description {
      text-align: center;
    }
    .card-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 10px 0;
      min-width: 230px;
      height: 30px;
      color: white;
      border-radius: 15px;
      background-color: #003e52;
      text-decoration: none;
      outline: none;
      cursor: pointer;
    }
  }
}
</style>
