<template v-if="itemsList && itemsList.length">
  <div v-if="itemsList && itemsList.length">
    <div v-for="item in itemsList" :key="item.id">
      <AccordionItem
        :item="item"
        @show-notification="onShowNotification"
        @update-notification="onUpdateNotification"
        :isAccordion="isAccordion"
        :hasNotificationUpdateButton="hasNotificationUpdateButton"
      />
    </div>
  </div>
</template>

<script>
import AccordionItem from '@/components/AccordionItem'

export default {
  name: 'AccordionList',
  components: {
    AccordionItem
  },
  props: {
    itemsList: {
      required: true
    },
    isAccordion: {
      required: false
    },
    hasNotificationUpdateButton: {
      required: false
    }
  },
  methods: {
    onShowNotification (item) {
      this.$emit('show-notification', item)
    },
    onUpdateNotification (item) {
      this.$emit('update-notification', item)
    }
  }
}
</script>
