<template>
  <div :class="{ 'is-grid': isGridMode, 'is-slider': isSliderMode }">
    <div class="icons-container">
      <SVGHandler class="tile-icon" :svgString="goal.icon" />
      <span v-if="goal.isCompleted" class="material-icons checked">
        check_circle
      </span>
    </div>
    <p class="tile-title">{{ goal.title }}</p>

    <div class="hover-container">
      <div class="title-section">
        <p class="hover-container-title">{{ goal.title }}</p>
        <p class="hover-paragraph">{{ goal.description }}</p>
      </div>
      <button type="button" :data-cy="`select-${goal.title}`" class="hover-select-button" @click="openOverlayForm(goal)" tabindex="0">
        {{ goal.isCompleted ? 'Edit' : 'Select' }}
      </button>
    </div>
  </div>
</template>

<script>
import SVGHandler from '@/utils/SVGHandler.vue'

export default {
  name: 'goal-tile-content',
  components: { SVGHandler },
  props: {
    goal: {
      type: Object,
      required: true
    },
    layoutMode: {
      type: String,
      required: true
    },
    openOverlayForm: {
      type: Function,
      required: true
    }
  },
  computed: { 
    isGridMode() {
      return this.layoutMode === 'grid'
    },
    isSliderMode() {
      return this.layoutMode === 'slider'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
@import '@/assets/styles/swoop/_mixins.scss';
.tile .is-grid {
  @include flex-display(column, center, center);
  height: 206px;
  position: relative;
  box-sizing: border-box;
  border-radius: 12px;
  background: $color-white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.098241), 0px 1px 3px rgba(0, 0, 0, 0.104539);

  &:hover,
  &:focus-visible {
    outline: none;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);

    .tile-title {
      height: 0;
      opacity: 0;
    }

    .icons-container {
      .tile-icon {
        fill: $color-select-goals-icons-fill;
        position: absolute;
        display: inline;
        margin-top: 8px;
        transform: scale(0.6);
        transition: transform 0.2s ease-in-out;
      }

      .checked {
        top: 8px;
        left: -12px;
        transform: scale(0.55);
        transition: transform 0.2s ease-in-out;
      }
    }

    .hover-container {
      display: flex;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;

      .hover-container-title {
        height: 0;
        opacity: 0;
      }
    }
  }

  &:focus-visible {
    border: 1px solid var(--color-primary-300);
  }

  &.other-btn {
    display: none;
  }

  .icons-container {
    @include flex-display(column, center, center);
    position: relative;

    .tile-icon {
      fill: $color-select-goals-icons-fill;
      width: 64px;
      min-width: 64px;
      height: 64px;
      min-height: 64px;
      transition: opacity 0.2s ease-in-out;

      /deep/.svg-background {
        fill: var(--color-select-goals-icons-background);
      }
    }

    .checked {
      position: absolute;
      top: 50px;
      left: 20px;
      transform: scale(0.85);
      color: $color-primary;
      transition: transform 0.2s ease-in-out;
    }
  }

  .tile-title {
    padding: 0 30px;
    margin: 0;
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 18.8px;
    color: var(--color-primary-400);

    &.other {
      margin-top: 0;
      margin-left: 16px;
    }
  }

  .hover-container {
    @include flex-display(column, center, center);

    display: none;
    opacity: 0;

    .hover-paragraph {
      margin: 8px 0;
      text-align: center;
      letter-spacing: -0.4px;
      line-height: 18px;
      font-size: 13px;
      font-weight: 500;
      color: var(--color-primary-400);
      padding: 0 12px;
    }

    .hover-select-button {
      @include flex-display(column, center, center);

      width: 70px;
      height: 32px;
      gap: 10px;
      margin-right: 8px;
      padding: 8px 12px;
      border: none;
      border-radius: 1000px;
      letter-spacing: -0.466667px;
      line-height: 16px;
      font-size: 14px;
      font-weight: 400;
      background: $color-select-goals-button;
      color: $color-white;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-md) {
  .tile {
    width: 100%;
    .is-grid {
      @include flex-display(row, center, flex-start);
      height: 56px;
      padding: 8px;

      .icons-container {
        .tile-icon {
          position: relative;
          right: 12px;
          transform: scale(0.5);
        }

        .material-icons.checked {
          left: 6px;
          top: 35px;
          transform: scale(0.5);
        }
      }

      .tile-title {
        margin: 0;
        padding: 0;
        text-align: start;
      }

      .hover-container {
        width: 0;

        .title-section {
          width: 0;
          height: 0;
        }
      }

      &:hover,
      &:focus-visible {
        height: fit-content;
        padding: 12px 8px;

        .icons-container {
          .tile-icon {
            transform: scale(0.7);
            top: auto;
            left: 0;
            margin-top: 0;
            margin-bottom: 8px;
          }
          .material-icons.checked {
            top: 5px;
            left: 20px;
            transform: scale(0.7);
            transition: transform 0.2s ease-in-out;
          }
        }

        .tile-title {
          width: 0;
        }

        .hover-container {
          @include flex-display(row, center, space-between);

          height: auto;
          width: 98%;
          padding-left: 64px;
          opacity: 1;
          transition: opacity 0.2s ease-in-out;

          .title-section {
            width: auto;
            height: auto;

            .hover-paragraph {
              width: 100%;
              margin: 0;
              padding: 0;
              padding-right: 8px;
              text-align: start;
            }

            .hover-container-title {
              height: auto;
              margin: 0;
              font-weight: 500;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.tile .is-slider {
  @include flex-display(column, center, center);

  margin: 5px 8px;
  height: 206px;
  width: 160px;
  position: relative;
  box-sizing: border-box;
  border-radius: 12px;
  background: $color-white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.098241), 0px 1px 3px rgba(0, 0, 0, 0.104539);

  &:hover,
  &:focus-visible {
    outline: none;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);

    .tile-title {
      height: 0;
      opacity: 0;
    }

    .icons-container {
      .tile-icon {
        fill: $color-select-goals-icons-fill;
        position: absolute;
        display: inline;
        margin-top: 8px;
        transform: scale(0.6);
        transition: transform 0.2s ease-in-out;
      }

      .checked {
        top: 22px;
        transform: scale(0.55);
        transition: transform 0.2s ease-in-out;
      }
    }

    .hover-container {
      width: 160px;
      display: flex;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;

      .hover-container-title {
        height: 0;
        opacity: 0;
      }
    }
  }

  &:focus-visible {
    border: 1px solid var(--color-primary-300);
  }

  &.other-btn {
    display: none;
  }

  .icons-container {
    @include flex-display(column, center, center);

    .tile-icon {
      fill: $color-select-goals-icons-fill;
      width: 64px;
      min-width: 64px;
      height: 64px;
      min-height: 64px;
      transition: opacity 0.2s ease-in-out;

      /deep/.svg-background {
        fill: var(--color-select-goals-icons-background);
      }
    }

    .checked {
      position: relative;
      bottom: 14px;
      transform: scale(0.85);
      color: $color-primary;
      transition: transform 0.2s ease-in-out;
    }
  }

  .tile-title {
    padding: 0 30px;
    margin: 0;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 18.8px;
    color: var(--color-primary-400);

    &.other {
      margin-top: 0;
      margin-left: 16px;
    }
  }

  .hover-container {
    @include flex-display(column, center, center);

    display: none;
    opacity: 0;

    .hover-paragraph {
      margin: 8px 0;
      text-align: center;
      letter-spacing: -0.4px;
      line-height: 18px;
      font-size: 13px;
      font-weight: 500;
      padding: 0 12px;
    }

    .hover-select-button {
      @include flex-display(column, center, center);

      width: 70px;
      height: 32px;
      gap: 10px;
      margin-right: 8px;
      padding: 8px 12px;
      border: none;
      border-radius: 1000px;
      letter-spacing: -0.466667px;
      line-height: 16px;
      font-size: 14px;
      font-weight: 400;
      background: $color-select-goals-button;
      color: $color-white;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  .tile .is-grid {
    &:hover,
    &:focus-visible {
      .hover-container {
        @include flex-display(column, flex-start, space-between);

        .title-section {
          text-align: start;
          margin-bottom: 8px;

          .hover-paragraph {
            text-align: start;
          }
        }
      }
    }
  }
}
</style>
