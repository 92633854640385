<template>
  <div>
    <label class="ui header text dark h1 m-b-2">
      {{$dictionary.welcomeHeader}}, {{ user.firstName }} {{ user.lastName }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'WelcomeDescription',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';
.welcome-user {
  color: var(--color-primary-500);
  font-style: normal;
  margin-bottom: 0px;
  display: inline-block;
}
.ui.header {
  display: block;
  color: $color-general-headers;
}
@media only screen and (max-width: 770px) {
  [class*='mobile hidden'],
  [class*='tablet only']:not(.mobile),
  [class*='computer only']:not(.mobile),
  [class*='large screen only']:not(.mobile),
  [class*='widescreen only']:not(.mobile),
  [class*='or lower hidden'],
  .icon {
    display: block;
  }
}
</style>
